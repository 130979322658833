<script setup>
import { onMounted } from "vue";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import router from "@/router";
import { useStore } from "@/core/services/store";
import { SET_INSTANCE } from "@/core/services/store/instance.module";

const url = new URL(window.location.href.replace("/#", ""));
const token = url.searchParams.get("token");
const store = useStore();

async function getInstance() {
  const url = process.env.VUE_APP_API_ADMIN + "/instance";
  await ApiService.get(url).then(response => {
    store.dispatch("instance/" + SET_INSTANCE, response.data);
  });
}

onMounted(async () => {
  if (token) {
    // Set bearer token
    JwtService.saveToken(token);
    ApiService.setHeader();

    if (!store.getters["instance/instance"]?.id) {
      await getInstance();
    }

    await router.push({
      name: "projectWorkflows"
    });
  } else {
    await router.push({
      name: "login"
    });
  }
});
</script>

<template>
  <div class="d-flex align-center justify-center w-100">
    <b-spinner class="align-middle" type="grow" variant="primary"></b-spinner>
  </div>
</template>

<style lang="scss" scoped>
.spinner-grow {
  width: 3.5rem;
  height: 3.5rem;
}
</style>
